<template>
  <v-dialog
    v-model="dialog"
    width="500"
    @click:outside="$emit('update:dialog', false)"
  >
    <v-card>
      <v-card-title class="primary--text px-4 py-1 text-body-1">
        {{ $t("generalEvaluation.today") }}
        <v-spacer />
        <v-btn icon @click="$emit('update:dialog', false)">
          <v-icon color="primary darken-1">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0 px-4">
        <v-simple-table dense>
          <tbody>
            <tr>
              <td class="font-weight-medium">{{ $t("generalEvaluation.customerOrders") }}</td>
              <td>{{ obligationDataList && obligationDataList.todayData ? numberWithCommas(obligationDataList.todayData.customer_order) : 0 }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">{{ $t("generalEvaluation.deliveryNotes") }}</td>
              <td>{{ obligationDataList && obligationDataList.todayData ? numberWithCommas(obligationDataList.todayData.delivery_note) : 0 }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">{{ $t("generalEvaluation.proformaInvoices") }}</td>
              <td>{{ obligationDataList && obligationDataList.todayData ? numberWithCommas(obligationDataList.todayData.proforma_invoice) : 0 }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">{{ $t("generalEvaluation.invoices") }}</td>
              <td>{{ obligationDataList && obligationDataList.todayData ? numberWithCommas(obligationDataList.todayData.invoice) : 0 }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">{{ $t("generalEvaluation.creditInvoices") }}</td>
              <td>{{ obligationDataList && obligationDataList.todayData ? numberWithCommas(obligationDataList.todayData.credit_invoice) : 0 }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">{{ $t("generalEvaluation.receipts") }}</td>
              <td>{{ obligationDataList && obligationDataList.todayData ? numberWithCommas(obligationDataList.todayData.receipt) : 0 }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-divider />
        <v-simple-table dense class="mt-3">
          <tbody>
            <tr>
              <td class="font-weight-medium">{{ $t("generalEvaluation.inflow") }}</td>
              <td>{{ obligationDataList && obligationDataList.todayData ? numberWithCommas(obligationDataList.todayData.inflow) : 0 }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">{{ $t("generalEvaluation.outflow") }}</td>
              <td>{{ obligationDataList && obligationDataList.todayData ? numberWithCommas(obligationDataList.todayData.outflow) : 0 }}</td>
            </tr>
            <tr>
              <td class="font-weight-medium">{{ $t("generalEvaluation.pendingDeposits") }}</td>
              <td>{{ obligationDataList && obligationDataList.todayData ? numberWithCommas(obligationDataList.todayData.pendding_deposit) : 0 }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "TodayDialog",
  props: ["dialog", "obligationDataList"],
  methods: {
    numberWithCommas(x) {
      return x ? parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (0);
    },
  },
};
</script>
