<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters class="mt-2">
      <v-col cols="10" class="mb-2">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1"
          >{{ $t("generalEvaluation.generalEvaluation") }}</span
        >
      </v-col>
      <v-col cols="2" class="mb-2">
        <HtmlToPdf :class="locale === 'he' ? 'float-left' : 'float-right'" class="me-2 mb-1" :model="obligationDataList" type="GenerateEvaluationReport" :showReceipt="showReceipt"/>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="3">
        <v-card class="mx-auto mb-4" flat>
          <v-card-title class="pa-0 primary--text">
            <v-icon color="primary">mdi-account-group-outline</v-icon>
            <h6 class="ms-2 font-weight-regular">
              {{ $t("generalEvaluation.customerObligations") }}
            </h6>
          </v-card-title>
          <v-card-text class="pa-0 px-2 mt-2">
            <v-simple-table dense>
              <tbody>
                <tr @dblclick="doubleClickReport(Object.keys(obligationDataList.customer_obligation_final_data)[0])">
                  <td class="font-weight-medium">{{ $t("generalEvaluation.debts") }}</td>
                  <td>{{ obligationDataList && obligationDataList.customer_obligation_final_data ? numberWithCommas(obligationDataList.customer_obligation_final_data.debt) : 0 }}</td>
                </tr>
                <tr @dblclick="doubleClickReport(Object.keys(obligationDataList.customer_obligation_final_data)[1])">
                  <td class="font-weight-medium">{{ $t("generalEvaluation.futureChecks") }}</td>
                  <td>{{ obligationDataList && obligationDataList.customer_obligation_final_data ? numberWithCommas(obligationDataList.customer_obligation_final_data.future_check) : 0 }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium">{{ $t("generalEvaluation.totalCustomers") }}</td>
                  <td>{{ obligationDataList && obligationDataList.customer_obligation_final_data ? numberWithCommas(obligationDataList.customer_obligation_final_data.total_customer) : 0 }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
        <v-card class="mx-auto mb-4" flat>
          <v-card-title class="pa-0 primary--text">
            <v-icon color="primary">mdi-account-badge-outline</v-icon>
            <h6 class="ms-2 font-weight-regular">
              {{ $t("generalEvaluation.supplierObligations") }}
            </h6>
          </v-card-title>
          <v-card-text class="pa-0 px-2 mt-2">
            <v-simple-table dense>
              <tbody>
                <tr @dblclick="doubleClickReport(Object.keys(obligationDataList.supplier_obligation_data)[0])">
                  <td class="font-weight-medium">{{ $t("generalEvaluation.debts") }}</td>
                  <td>{{ obligationDataList && obligationDataList.supplier_obligation_data ? numberWithCommas(obligationDataList.supplier_obligation_data.supplier_debt) : 0 }}</td>
                </tr>
                <tr @dblclick="doubleClickReport(Object.keys(obligationDataList.supplier_obligation_data)[1])">
                  <td class="font-weight-medium">{{ $t("generalEvaluation.supplierChecks") }}</td>
                  <td>{{ obligationDataList && obligationDataList.supplier_obligation_data ? numberWithCommas(obligationDataList.supplier_obligation_data.supplier_check) : 0 }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium">{{ $t("generalEvaluation.totalSuppliers") }}</td>
                  <td>{{ obligationDataList && obligationDataList.supplier_obligation_data ? numberWithCommas(obligationDataList.supplier_obligation_data.total_supplier) : 0 }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
        <v-card class="mx-auto mb-4" flat>
          <v-card-text class="pa-0 px-2 mt-2">
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td class="font-weight-medium">{{ $t("generalEvaluation.totalObligations") }}</td>
                  <td>{{ obligationDataList && obligationDataList.total_obligation_data ? numberWithCommas(obligationDataList.total_obligation_data.total_obligations) : 0 }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="3">
        <v-card class="mx-auto mb-4" flat>
          <v-card-title class="pa-0 primary--text">
            <v-icon color="primary">mdi-book-open-page-variant-outline</v-icon>
            <h6 class="ms-2 font-weight-regular">
              {{ $t("generalEvaluation.incomeForecast") }}
            </h6>
          </v-card-title>
          <v-card-text class="pa-0 px-2 mt-2">
            <v-simple-table dense>
              <tbody>
                <tr @dblclick="doubleClickReport(Object.keys(obligationDataList.income_forecast)[0])">
                  <td class="font-weight-medium">{{ $t("generalEvaluation.deliveryNotes") }}</td>
                  <td>{{ obligationDataList && obligationDataList.income_forecast ? numberWithCommas(obligationDataList.income_forecast.delivery_note) : 0 }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium">{{ $t("generalEvaluation.agentDeliveryNotes") }}</td>
                  <td>{{ obligationDataList && obligationDataList.income_forecast ? numberWithCommas(obligationDataList.income_forecast.agent_delivery_note) : 0 }}</td>
                </tr>
                <tr @dblclick="doubleClickReport(Object.keys(obligationDataList.income_forecast)[2])">
                  <td class="font-weight-medium">{{ $t("generalEvaluation.proformas") }}</td>
                  <td>{{ obligationDataList && obligationDataList.income_forecast ? numberWithCommas(obligationDataList.income_forecast.proforma_invoice) : 0 }}</td>
                </tr>
                <tr @dblclick="doubleClickReport(Object.keys(obligationDataList.income_forecast)[3])">
                  <td class="font-weight-medium">{{ $t("generalEvaluation.orderInProduction") }}</td>
                  <td>{{ obligationDataList && obligationDataList.income_forecast ? numberWithCommas(obligationDataList.income_forecast.order_sum) : 0 }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium">{{ $t("generalEvaluation.totalForecast") }}</td>
                  <td>{{ obligationDataList && obligationDataList.income_forecast ? numberWithCommas(obligationDataList.income_forecast.total_forcast) : 0 }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
        <v-card class="mx-auto mb-4" flat>
          <v-card-title class="pa-0 primary--text">
            <v-icon color="primary">mdi-cart</v-icon>
            <h6 class="ms-2 font-weight-regular">
              {{ $t("generalEvaluation.expensesForecast") }}
            </h6>
          </v-card-title>
          <v-card-text class="pa-0 px-2 mt-2">
            <v-simple-table dense>
              <tbody>
                <tr @dblclick="doubleClickReport(Object.keys(obligationDataList.expence_forecast)[0])">
                  <td class="font-weight-medium">{{ $t("generalEvaluation.supplierOrders") }}</td>
                  <td>{{ obligationDataList && obligationDataList.expence_forecast ? numberWithCommas(obligationDataList.expence_forecast.supplier_order) : 0 }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
        <v-card class="mx-auto mb-4" flat>
          <v-card-text class="pa-0 px-2 mt-2">
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td class="font-weight-medium">{{ $t("generalEvaluation.totalDifference") }}</td>
                  <td>{{ obligationDataList && obligationDataList.total_difference ? numberWithCommas(obligationDataList.total_difference.total_difference) : 0 }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="3">
        <v-row>
          <v-col cols="6">
            <v-btn
              color="primary"
              small
              @click="todayDialog = true"
            >
              {{ $t("generalEvaluation.today") }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              color="primary"
              small
              @click="refresh"
            >
              {{ $t("generalEvaluation.refresh") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-checkbox
              class="mt-2"
              :dense="true"
              v-model="withoutVat"
              :label="$t('generalEvaluation.withoutVat')"
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              :dense="true"
              v-model="addProformas"
              :label="$t('generalEvaluation.addProformas')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-select
              dense
              v-model="year"
              :items="years"
              :label="$t('documentSeries.year')"
              outlined
              @change="yearChange"
            />
          </v-col>
          <v-col cols="6">
            <v-btn
              color="primary"
              small
              @click="multiYearDialog = true"
            >
              {{ $t("generalEvaluation.multiYear") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
    <v-btn
      :class="locale === 'he' ? 'float-left' : 'float-right'"
      class="mt-2"
      color="primary"
      small
      @click="showPurchase"
    >
      {{ showReceipt ? $t("generalEvaluation.showPurchase") : $t("generalEvaluation.showReceipt") }}
    </v-btn>
    <v-row no-gutters>
      <v-col cols="6">
        <v-card class="mx-auto mb-4 mt-3" flat>
          <v-card-text class="pa-0 px-2 mt-2">
            <v-simple-table dense style="width: 80%;">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th :class="locale === 'he' ? 'text-right' : 'text-left'">{{ $t('generalEvaluation.month') }}</th>
                    <th :class="locale === 'he' ? 'text-right' : 'text-left'">{{ $t('generalEvaluation.invoices') }}</th>
                    <th :class="locale === 'he' ? 'text-right' : 'text-left'">{{ $t('generalEvaluation.accumulated') }}</th>
                    <th :class="locale === 'he' ? 'text-right' : 'text-left'">%</th>
                  </tr>
                </thead>
                <tbody v-if="obligationDataList && obligationDataList.invoice">
                  <tr v-for="(data, index) in obligationDataList.invoice.invoiceRecords" :key="index" @dblclick="doubleClickReport(data)">
                    <td class="font-weight-medium">{{ $t(`generalEvaluation.monthList.${data.month}`) }}</td>
                    <td>{{ numberWithCommas(data.invoice) }}</td>
                    <td>{{ numberWithCommas(data.accumulated) }}</td>
                    <td>{{ numberWithCommasFloat(data.percentage) }}%</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="5">
        <v-card class="mx-auto mb-4 mt-3" flat>
          <v-card-text class="pa-0 px-2 mt-2">
            <v-simple-table dense style="width: 80%;">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th :class="locale === 'he' ? 'text-right' : 'text-left'">{{ showReceipt ? $t("generalEvaluation.receipts") : $t("generalEvaluation.purchases") }}</th>
                    <th :class="locale === 'he' ? 'text-right' : 'text-left'">{{ $t('generalEvaluation.accumulated') }}</th>
                    <th :class="locale === 'he' ? 'text-right' : 'text-left'">%</th>
                  </tr>
                </thead>
                <tbody v-if="obligationDataList && obligationDataList.receipt && showReceipt">
                  <tr v-for="(data, index) in obligationDataList.receipt.receiptRecords" :key="index" @dblclick="doubleClickReport(data)">
                    <td>{{ numberWithCommas(data.receipt) }}</td>
                    <td>{{ numberWithCommas(data.receiptAccumulated) }}</td>
                    <td>{{ numberWithCommasFloat(data.percentage) }}%</td>
                  </tr>
                </tbody>
                <tbody v-else-if="obligationDataList && obligationDataList.purchase && !showReceipt">
                  <tr v-for="(data, index) in obligationDataList.purchase.purchaseRecords" :key="index" @dblclick="doubleClickReport(data)">
                    <td>{{ numberWithCommas(data.purchase) }}</td>
                    <td>{{ numberWithCommas(data.purchaseAccumulated) }}</td>
                    <td>{{ numberWithCommasFloat(data.percentage) }}%</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
    <v-overlay :z-index="999" v-if="isProgressCircular">
      <v-icon size="64" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
    </v-overlay>
    <TodayDialog v-if="todayDialog" :dialog.sync="todayDialog" :obligationDataList="obligationDataList" />
    <MultiYearDialog v-if="multiYearDialog" :dialog.sync="multiYearDialog" :obligationDataList="obligationDataList.multiYearData" :selectedYear="year"/>
    <DoubleClickReport v-if="doubleClickReportDialog" :dialog.sync="doubleClickReportDialog" :doubleClickData="doubleClickData" :selectedYear="year" :withoutVat="withoutVat"/>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import TodayDialog from "./TodayDialog.vue";
import MultiYearDialog from "./MultiYearDialog.vue";
import DoubleClickReport from "./DoubleClickReport.vue";

export default {
  name: "GeneralEvaluation",
  data() {
    return {
      todayDialog: false,
      multiYearDialog: false,
      withoutVat: true,
      addProformas: false,
      year: new Date().getFullYear(),
      years: [],
      showReceipt: true,
      isProgressCircular: false,
      doubleClickReportDialog: false,
      doubleClickData: null
    }
  },
  components: {
    HtmlToPdf,
    TodayDialog,
    MultiYearDialog,
    DoubleClickReport,
  },
  computed: {
    ...mapGetters({
      obligationDataList: "generalEvaluation/obligationDataList",
      accessRight: "accessRight",
      locale: "locale",
    }),
  },
  async created() {
    this.isProgressCircular = true;
    if (this.withoutVat) {
      await this.$store.dispatch("generalEvaluation/GetObligationData", `?year=${this.year}&vat=true`);
    } else {
      await this.$store.dispatch("generalEvaluation/GetObligationData", `?year=${this.year}`);
    }
    this.isProgressCircular = false;
    const startYear = 1990;
    const currentYear = new Date().getFullYear();
    for (let year = currentYear+1; year >= startYear; year--) {
      this.years.push(year);
    }
  },
  methods: {
    numberWithCommas(x) {
      return x ? parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (0);
    },
    numberWithCommasFloat(x) {
      return x ? parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (0).toFixed(2);
    },
    async refresh() {
      this.isProgressCircular = true;
      if (this.withoutVat) {
        await this.$store.dispatch("generalEvaluation/GetObligationData", `?year=${this.year}&vat=true`);
      } else {
        await this.$store.dispatch("generalEvaluation/GetObligationData", `?year=${this.year}`);
      }
      this.isProgressCircular = false;
    },
    doubleClickReport(data) {
      this.doubleClickReportDialog = true;
      this.doubleClickData = data;
    },
    async yearChange(year) {
      this.year = year;
      this.refresh();
    },
    showPurchase() {
      this.showReceipt = !this.showReceipt;
    }
  }
};
</script>
